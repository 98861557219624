.fsp-drop-pane__container {
  background-color: white !important;
  width: 24rem !important;
}

.fsp-drop-pane__icon {
  background-image: url("../images/add_file_icon.svg") !important;
}

.fsp-drop-pane__text {
  text-align: center;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: rgb(75, 85, 99) !important;
  padding-left: 3rem;
  padding-right: 3rem;
}

.fsp-drop-pane__text:nth-child(1) {
  color: #009926;
}