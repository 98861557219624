@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "policy_details";

[x-cloak] {
  display: none;
}

.tooltip {
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  position: absolute;


  /* Position the tooltip */
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}